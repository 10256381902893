<template>
    <v-input :label="label">
        <v-progress-circular v-if="processing" indeterminate color="primary"></v-progress-circular>
        <div v-else>
            <input ref="file" type="file" :multiple="false" @change="fileChanged()">
            <v-btn class="file-upload" color="primary" @click="pick">
                Choose file
            </v-btn>
        </div>

        {{file && file.name}}
        <v-btn v-if="file" @click="clear" text icon>
            <v-icon>close</v-icon>
        </v-btn>

        <v-alert v-if="error" type="error" :value="true">
            {{ error.message }}
        </v-alert>
    </v-input>
</template>

<script>
import api from '@/services/api';

export default {
  props: [
    'value',
    'label',
  ],
  data() {
    return {
      file: null,
      processing: false,
      error: false,
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value == null) {
          this.file = null;
          return;
        }
        if (this.file != null && this.file.id === this.value) {
          return;
        }

        api.kernel.get(`/files/${this.value}`).then((data) => {
          this.file = data;
        }, (error) => {
          this.error = error;
        });
      },
    },
  },
  methods: {
    pick() {
      this.$refs.file.click();
    },
    clear() {
      this.file = null;
      this.$emit('input', null);
    },
    fileChanged() {
      this.error = null;
      this.processing = true;
      api.kernel.postFile('/files', this.$refs.file.files[0]).then((data) => {
        this.error = null;
        this.processing = false;
        this.file = data;
        this.$emit('input', data.id);
      }, (error) => {
        this.error = error;
        this.processing = false;
      });
    },
  },
};

</script>

<style lang="scss">
input[type="file"] {
    position: absolute;
    top: -1000px;
    left: -1000px;
    width: 1px;
    height: 1px;
    opacity: 0;
}
</style>
